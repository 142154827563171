// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import {ArrowBackIosRounded, AddCircleOutline, RemoveCircleOutline} from "@material-ui/icons";

const theme = createTheme({
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "Josefin Sans !important",
      }, 
      body1: {
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "32px",
      },
      h2: {
        fontSize: "36px",
        fontWeight: 700,
        lineHeight: "44px",
      },      
      body2: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20px",
      },
      h3: {
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
      },
      h1: {
        fontSize: "48px",
        fontWeight: 700,
        lineHeight: "56px",
      },
    },
    MuiAccordion: {
      root: {
        boxShadow: "none",
        marginLeft:'24px',
        borderBottom: "2px solid #E2E8F0",
        "&:before": {
          backgroundColor: "transperant !important",
        },
        "& .MuiPaper-root.MuiAccordion-root": {
          boxShadow: "none",
        },
        "& .MuiAccordion-root.Mui-expanded::before": {
          border: "1px solid #E2E8F0 !important",
          marginLeft:'24px',
        },
        "& .MuiAccordionSummary-content": {
          fontFamily: "Josefin Sans",
          color: "#94A3B8",
          fontSize: "20px",
          fontWeight: 700,
          padding: '12px 0 5px',
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
          color: "#0F172A !important",
          marginLeft:'24px',
        },
        "& .MuiAccordionDetails-root": {
          fontFamily: "Outfit",
          marginLeft:'24px',
          fontSize: "16px",
          fontWeight: 400,
          color: "#9095A1",
        },
      },
    },        
    MuiButton: {
      root: {
        fontWeight: 700,
        fontSize: "18px",
        fontFamily: "Josefin Sans",
        lineHeight: '24px',
      },      
      outlined: {
        border: "1px solid #64748B !important",
        color: "#64748B",
        textTransform: "none",
        "&:hover": {
          color: "#FFFFFF",
          backgroundColor: "#4F9FF8",
          }
      },
      contained: {
        borderColor: "#4F9FF8 !important",
        backgroundColor: "#4F9FF8",
        color: "#FFFFFF",
        textTransform: "none",
        "&:hover": {
          color: "#4F9FF8",
          backgroundColor: "#FFFFFF",
          }
      },
    },
  }
});
 
import JobListingController, {
  Props,
  configJSON,
} from "./JobListingController.web";

class FAQs extends JobListingController {
  constructor(props: Props) {
    super(props);
  }
  static FAQs: FAQs;
  render() {
    const {faqDataList} = this.state;    
    return (
      <>
      <ThemeProvider theme={theme}>
            <MainWrapper data-test-id="jobListingContainer">            
                <Container maxWidth="lg">
                  <Header navigation={this.props.navigation} />
                  <Box style={{marginBottom:'100px'}}>
                      <div className="iconPlace" style={{flexDirection: 'row'}}>
                        <ArrowBackIosRounded style={{cursor:'pointer'}} onClick={()=> this.goBack()} data-test-id='backBtn'/>
                        <Typography
                          data-test-id="Frequently"
                          className="titleText"
                        >
                          {configJSON.titleFAQs}
                        </Typography>
                      </div>
                      <Box style={{ minHeight: "26vh" }}>
                      { faqDataList.length > 0 ?
                      faqDataList?.map((faqs, index) => {
                        return (
                          <Accordion
                            defaultExpanded
                            data-test-id={`panel${index + 1}`}
                            expanded={
                              this.state.expandedAccordionPanel === `panel${index + 1}`
                            }
                            onChange={this.setExpandedAccordionPanel(
                              `panel${index + 1}`
                            )}
                          >
                            <AccordionSummary
                              data-test-id={`panel${index + 1}-summary`}
                              expandIcon={
                                this.state.expandedAccordionPanel ===
                                `panel${index + 1}` ? (
                                  <RemoveCircleOutline />
                                ) : (
                                  <AddCircleOutline/>
                                )
                              }
                              aria-controls={`panel${index + 1}-content`}
                              id={`panel${index + 1}-header`}
                            >
                              {faqs.attributes.question}
                            </AccordionSummary>
                            <AccordionDetails
                              data-test-id={`panel${index + 1}-details`}
                            >
                              {faqs.attributes.answer}
                            </AccordionDetails>
                          </Accordion>
                        );
                      })
                      :
                        <Typography className="title2">No FAQs data found</Typography>
                      }
                      </Box>
                  </Box>
                </Container>                
                <Footer navigation={this.props.navigation}/>
            </MainWrapper>
        </ThemeProvider>       
    </>
    );
  }
}

export default FAQs;

const MainWrapper = styled('div')(({ theme }) => ({ 
  "& .title2": {
    fontFamily: 'Josefin Sans',
    fontSize: '24px',
    fontWeight: 700,
    marginTop: '47px',
    justifyContent: 'center',
    display: 'flex',
    color: '#94A3B8'
  },
  "& .iconPlace": {
    display: 'flex',
    margin: "50px 0 20px 0",
    gap: '14px',
    marginLeft:'-30px',
    alignItems: 'baseline',
    '@media (max-width: 1300px)': {
      marginLeft:'0px',
    },
  },
  "& .titleText": {
    fontWeight: "700",
    fontSize: "36px",
    letterSpacing: "-0.01em",    
    fontStyle:'Josefin Sans',
    lineHeight: 1.1,
  },
}));
// Customizable Area End
