// Customizable Area Start
import React from "react";
import {
  TableContainer,
  Table,
} from "@material-ui/core";
import { ThemeProvider, createTheme, styled } from "@material-ui/core/styles";
import ContactUsModal from "../../emailnotifications2/src/ContactUsModal.web";
import Toast from "../../../components/src/Toast";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
    success: {
      main: "#059669",
    },
  },
  overrides: {
    MuiTableCell: {
      head: {
        padding: "1.1rem",
        fontFamily: "Outfit",
        fontWeight: 700,
        color: "#475569",
      },
      body: {
        padding: "1.3rem",
        fontFamily: "Outfit",
        fontWeight: 400,
        color: "#0F172A",
      },
    },
    MuiTypography: {
      body1: { fontFamily: "Outfit", fontWeight: 700, color: "#475569" },
      body2: { fontFamily: "Outfit", fontWeight: 400, color: "#0F172A" },
      subtitle1: {
        fontSize: "0.8rem",
        fontFamily: "Outfit",
        fontWeight: 400,
        color: "#64748B",
      },
    },
    MuiCard: {
      root: {
        border: "1px solid #E2E8F0",
        boxShadow: "none",
      },
    },
  },
});
import ReservationTableController, {
  Props,
} from "./ReservationTableController.web";

export default class ReservationTable extends ReservationTableController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (<>
    {this.state.errorMsg && (
        <Toast data-test-id="errorMsg" message={this.state.errorMsg} type="error" />
      )}
      <ThemeProvider theme={theme}>
        <Wrapper data-test-id="mainWrapper">
          <TableContainer
            className="tableVisible"
            data-test-id={this.props.testId}
          >
            <Table>
              {this.renderTableHeader()}
              {this.renderTableBody()}
            </Table>
          </TableContainer>
          {this.renderCardView()}
          {this.renderPaginationAndSummary()}
        </Wrapper>
      </ThemeProvider>
      <ContactUsModal
      isOpen={this.state.openModal}
      onClose={() => this.setState({ openModal: false })}
      id="contact modal"
    /></>
    );
  }
}

const Wrapper = styled("div")(({ theme }) => ({
  "& .marginAll": { margin: "10px 10px 0 0" },
  "& .paddingButton": {
    padding: "5px",
  },
  "& .needText": {
    fontFamily: "outfit",
    fontWeight: 600,
    fontSize:'16px',
    color:'#0F172A'
  },
  "& .successButton": {
    textTransform: "capitalize",
    fontFamily: "outfit",
    fontWeight: 400,
    color: "#059669",
    border: "1px solid #059669",
    backgroundColor: "#ECFDF5",
    borderRadius: "12px",
    width:'100px',
    '@media (max-width: 800px)': {
      width: "141px",
    },
  },
  "& .warningButton": {
    textTransform: "capitalize",
    fontFamily: "outfit",
    fontWeight: 400,
    color: "#B19969",
    border: "1px solid #B19969",
    backgroundColor: "#FFFBF5",
    borderRadius: "12px",
    width:'100px',
    '@media (max-width: 800px)': {
      width: "141px",
    },
  },
  "& .chatButton":{
    textTransform: "none",
    fontFamily: "outfit",
    fontWeight: 400,
    color: "#4F9FF8",
    border: "1px solid #4F9FF8",
    borderRadius: "14px",
    width:'280px',
    '@media (max-width: 800px)': {
      width: "290px",
    },
  },
  "& .errorButton": {
    textTransform: "capitalize",
    fontFamily: "outfit",
    fontWeight: 400,
    color: "#B91C1C",
    border: "1px solid #B91C1C",
    backgroundColor: "#FEF2F2",
    borderRadius: "12px",
    width:'100px',
    '@media (max-width: 800px)': {
      width: "141px",
    },
  },
  "& .successKYCButton": {
    color: "#059669",
    border: "1px solid #059669",
    backgroundColor: "#ECFDF5",
    borderRadius: "12px",
  },
  "& .warningKYCButton": {
    color: "#B19969",
    border: "1px solid #B19969",
    backgroundColor: "#FFFBF5",
    borderRadius: "12px",
  },
  "& .errorKYCButton": {
    color: "#B91C1C",
    border: "1px solid #B91C1C",
    backgroundColor: "#FEF2F2",
    borderRadius: "12px",
  },
  "& .rejectButton": {
    textTransform: "none",
    fontFamily: "outfit",
    fontWeight: 400,
    color: "#fff",
    backgroundColor: "#B91C1C",
    borderRadius: "14px",
    width:'280px',
    '@media (max-width: 800px)': {
      width: "290px",
    },
  },
  "& .Mui-selected": {
    fontWeight: 700,
    backgroundColor: "transparent",
    textDecoration: "none !important",
  },
  "& .pagination": {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    padding: "50px",
    gap: 40,
    "& button": {
      textDecoration: "underline",
    },
  },
  "& .cardVisible": {
    display: "none",
  },
  "& .cardContent": {
    display: "flex",
    gap: 10,
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  },
  [theme.breakpoints.down(500)]: {
    "& .pagination": {
      padding: "30px 20px",
      gap: 5,
    },
  },
  [theme.breakpoints.down(800)]: {
    "& .cardVisible": {
      display: "flex",
      flexWrap: "wrap",
      gap: 10,
      justifyContent: "center",
      alignItems: "center",
      //   flexDirection: "column",
    },
    "& .tableVisible": {
      display: "none",
    },
  },
}));

// Customizable Area End
