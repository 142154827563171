// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IHeader } from "./ReservationListController.web";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { Pagination } from "@material-ui/lab";
import { getStorageData } from "../../../framework/src/Utilities";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import PriorityHighRoundedIcon from '@material-ui/icons/PriorityHighRounded';

export const configJSON = require("./config");
export const assets = require("./assets");

interface NewActionState {
  [key: string]: string | number;
};

export interface Props {
  headerList: IHeader[];
  listData: {
    [key: string]: string | number;
  }[];
  testId: string;
  actions?: string[];
  active?: string[];
  totalCount:number;
  totalPages:number;
  isKycDisplayed?: boolean;
  redirectToDetails?: boolean;
  pageChange?: (page: number) => void;
  navigation?:object
}

interface State {
  currentPage: number;
  newAction: NewActionState;
  needSupport :boolean;
  openModal: boolean,
  errorMsg: string;
}

interface SSProps {
  ssId: string;
}

export default class ReservationTableController extends BlockComponent<
  Props,
  State,
  SSProps
> {
  itemsPerPage: number = 6;
  acceptReservationApiCallId : string = '';
  createChatApiCallId: string = '';
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];
    this.state = {
      currentPage: 1,
      newAction: {},
      needSupport: false,
      openModal: false,
      errorMsg:'',
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    let urlData = window.location.pathname;
    let parts = urlData.split("/");
    parts.includes("where-you-are-docking") ? this.setState({needSupport: true}): this.setState({needSupport: false});
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    this.createChatResponse(message);
  }
  handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({
      currentPage: value,
    });
    if (this.props.pageChange) {
      this.props.pageChange(value);
    }
  };

  acceptReservation = async(actionName: string | number,dockId:string | number ) => {
    const token = await getStorageData("token");
    const itemHeaders = {
      "Content-Type": configJSON.itemListApiContentType,
      'token': token
    };

    const acceptRes = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.acceptReservationApiCallId = acceptRes.messageId;

    acceptRes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.urlGetItemList}/${dockId}/resolve?act=${actionName.toString().toLowerCase()}`
    );

    acceptRes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(itemHeaders)
    );
    acceptRes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editReservationApiMethodType
    );

    runEngine.sendMessage(acceptRes.id, acceptRes);
    return true;
  };
  navigateToHostChat = (route:string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  createChatResponse = async(message:Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiCreateChatResCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseChatJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if (apiCreateChatResCallId === this.createChatApiCallId) { 
        if(responseChatJson.meta === "Chat created successfully"){
          this.navigateToHostChat('HostChat')
        } 
        if (responseChatJson.errors) {
          this.setState({ errorMsg: responseChatJson.errors[0] });
          setTimeout(()=>{
            this.setState({ errorMsg: '' });
          },2000)
        } 
      }
    }
  }
  createChat = async (listItem: {[key: string]: string | number}) => {
    const token = await getStorageData("token");
    const host_id = await getStorageData("loginId");
    const chatBody = {
      "chat": {
        'host_id': host_id,
        'boater_id': listItem.author_id,
        'dock_listing_id': listItem.dockId
      }
    }
    const itemHeaders = {
      "Content-Type": "application/json",
      'token': token
    };

    const ChatRes = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createChatApiCallId = ChatRes.messageId;

    ChatRes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.chatPath
    );

    ChatRes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(itemHeaders)
    );
    ChatRes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.secureReservationApiMethodType
    );
    ChatRes.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(chatBody)
    );
    runEngine.sendMessage(ChatRes.id, ChatRes);
    return true;
  };
  renderTableHeader() {
    const { headerList, actions, isKycDisplayed } = this.props;
    return (
      <TableHead>
        <TableRow>
          {headerList.map((headerItem) => (
            <TableCell key={headerItem.id}>{headerItem.label}</TableCell>
          ))}
          {actions &&
            actions.map((action) => (
              <TableCell
                key={action}
                align={action === "Status" ? "center" : "left"}
              >
                {action}
              </TableCell>
            ))}
            {isKycDisplayed &&
              <TableCell align="center">
                KYC
              </TableCell>
            }
        </TableRow>
      </TableHead>
    );
  }
  getButtonClassName = (action: string, status: string | number) => {
    if (action === "Reject") {
      return "errorButton";
    } else if (action === "Accept") {
      return "successButton";
    } else if (action === "Status" && status === "pending") {
      return "warningButton";
    } else if (action === "Status" && status === "cancelled") {
      return "errorButton";
    } else if (action === "Status" && status === "booked") {
      return "successButton";
    } else if (action === "KYC" && status === "in_progress") {
      return "warningKYCButton";
    } else if (action === "KYC" && status === "is_fail") {
      return "errorKYCButton";
    } else if (action === "KYC" && status === "is_done") {
      return "successKYCButton";
    } else {
      return ""; 
    }
  };
  getBtnName = (showChat:boolean) =>{
    return showChat ? "Chat" : "Rejected"
  }
  getBtnClassName = (showChat:boolean) =>{
    return showChat? "chatButton" :'rejectButton'
  }
  getChatIcon = (showChat:boolean) =>{
    return showChat ? <img src={assets.chatIcon} alt="chat"/> : null
  }
  getRejectIcon = (action:string) =>{
    return action === "Reject" ? <ClearIcon /> : null
  }
  getAlign = (action:string) => {
    return action === "Status" ? "center" : "left"
  }   
  getStartIcon = (action:string) => {
    const rejectIcon = this.getRejectIcon(action);
    return action === "Accept" ? <DoneIcon /> : rejectIcon;
  }  
  getActionValue = (status: string | number, action:string) => {    
    return action === "Status" ? status : action;
  }  
  getPandingData = (listItem: {[key: string]: string | number}, actions: string[]| undefined ,active: string[]| undefined) =>{
    if (listItem.status === 'pending' && actions && !active) {
      return actions.map((action, index) => {
                const isActionTaken = this.state.newAction[listItem.id];
                const showChatButton = isActionTaken === "Accept";
                const showRejectedButton = isActionTaken === "Reject";
                const chatIcons = this.getChatIcon(showChatButton);
                const btnName = this.getBtnName(showChatButton);
                if (showChatButton || showRejectedButton) {
                  if (index === 0) {
                  return (
                    <TableCell key={action} colSpan={2} align="left">
                      <Button 
                        className={this.getBtnClassName(showChatButton)}
                        startIcon={chatIcons}
                        onClick={()=>showChatButton && this.createChat(listItem)}
                      >
                        {btnName}
                      </Button>
                    </TableCell>
                  );}
                  return null;
                }

                const startIcon = this.getStartIcon(action);
                const actionValue =
                  action === "Status" ? listItem.status : action;
                  const classNames = this.getButtonClassName(action, listItem.status);
                return (
                  <TableCell
                    key={action}
                    align={this.getAlign(action)}
                  >
                    <Button
                      variant="outlined"
                      className={classNames}
                      startIcon={startIcon}
                      data-test-id='status-button'
                      onClick={() => this.handleButtonClick(actionValue, listItem.id)}
                    >
                      {actionValue}
                    </Button>
                  </TableCell>
                );
              })
            }
            return null; 
  }
  getCancelledData = (listItem: {[key: string]: string | number}, actions: string[]| undefined ,active: string[]| undefined) =>{
     if (listItem.status === 'cancelled' && actions && !active) {
      return <TableCell colSpan={2} align="left">
      <Button 
        className={`marginAll rejectButton`}
      >
       Rejected
      </Button>
    </TableCell>
     }
     return null;
  }
  getBookedData = (listItem: {[key: string]: string | number}, actions: string[]| undefined ,active: string[]| undefined) =>{
    if(listItem.status === 'booked' && actions && !active){
      return <TableCell colSpan={2} align="left">
      <Button 
        className={`marginAll chatButton`}
        startIcon={this.getChatIcon(true)}
        onClick={()=>this.createChat(listItem)}
      >
       {this.getBtnName(true)}
      </Button>
    </TableCell> 
    } return null;
  }

  getKYCButtonIcon = (action: string, status: string | number) => {
    if (action === "KYC" && status === "in_progress") {
      return <PriorityHighRoundedIcon />;
    } else if (action === "KYC" && status === "is_fail") {
      return <CloseRoundedIcon />;
    } else if (action === "KYC" && status === "is_done") {
      return <CheckRoundedIcon />;
    }
  };

  renderTableBody() {
    const { headerList, listData, actions, active, isKycDisplayed } = this.props;
    return (
      <TableBody>
        {listData.map((listItem) => (
          <TableRow key={listItem.id}>
            {headerList.map((header) => (
              <TableCell key={header.id} style={{width: header.id === 'listing' ? '300px':''}}>
                {listItem[header.id] || "Not Provided"}
              </TableCell>
            ))}   
            {active && 
              <TableCell
                align={'center'}
              >
                <Button
                  variant="outlined"
                  className={this.getButtonClassName('Status', listItem.status)}
                  data-test-id='status-active'
                >
                  {listItem.status}
                </Button>
              </TableCell>
            } 
            {this.getPandingData(listItem, actions, active)}
            {this.getCancelledData(listItem, actions, active)}           
            {this.getBookedData(listItem, actions, active)}
            {isKycDisplayed && (
              <TableCell align={'center'}>
                <Button
                  variant="outlined"
                  className={this.getButtonClassName('KYC', listItem.kyc_status)}
                >
                  {this.getKYCButtonIcon('KYC', listItem.kyc_status)}
                </Button>
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    );
  }
  handleButtonClick = (action: string | number, listId: string | number) => {    
    this.acceptReservation(action, listId);
    this.setState((prevState) => ({
      newAction: { ...prevState.newAction, [listId]: action },
    }))    
  }
  getPandingCardData = (listItem: {[key: string]: string | number}, actions: string[]| undefined ,active: string[]| undefined) => {
    if(listItem.status === 'pending' && actions && !active){
      return actions.map((action, index) => {
        const isActionTaken = this.state.newAction[listItem.id];
        const showChatButton = isActionTaken === "Accept";
        const showRejectedButton = isActionTaken === "Reject";
        const chatIcons = this.getChatIcon(showChatButton);
        
        if (showChatButton || showRejectedButton) {
          if (index === 0) {
          return (
              <Button 
                className={`marginAll ${this.getBtnClassName(showChatButton)}`}
                startIcon={chatIcons}
                data-test-id='accept-btn'
                onClick={()=>showChatButton && this.createChat(listItem)}
              >
                {this.getBtnName(showChatButton)}
              </Button>
          );}
          return null;
        }
        
        const startIcon = this.getStartIcon(action);
        const actionValue = this.getActionValue(listItem.status, action);
        return (
          <Button
            key={action}
            variant="outlined"
            className={`marginAll ${
              action === "Reject" ? "errorButton" : "successButton"
            }`}
            startIcon={startIcon}
            onClick={() => this.handleButtonClick(actionValue, listItem.id)}
          >
            {actionValue}
          </Button>
        );
      })
    } return null;
  }
  getCancelledCardData = (listItem: {[key: string]: string | number}, actions: string[]| undefined ,active: string[]| undefined) => {
    if (listItem.status === 'cancelled' && actions && !active) {
      return <Button
        className={`marginAll rejectButton`}
      >
        Rejected
      </Button>
    } return null;
  }
  getBookedCardData = (listItem: {[key: string]: string | number}, actions: string[]| undefined ,active: string[]| undefined) => {
    if (listItem.status === 'booked' && actions && !active) {
      return <Button
        className={`marginAll chatButton`}
        startIcon={this.getChatIcon(true)}
        onClick={()=>this.createChat(listItem)}
      >
        {this.getBtnName(true)}
      </Button>
    } return null;
  }
  renderCardView() {
    const { headerList, listData, actions, active, isKycDisplayed } = this.props;
    return (
      <Grid container spacing={3} className="cardVisible">
        {listData.map((listItem) => {        
          return(
          <Grid item xs={12} sm={12} md={6} key={listItem.id}>
            <Card key={listItem.id}>
              <CardContent>
                {headerList.map((header) => (
                  <Box key={header.id} className="cardContent">
                    <Typography variant="body1">{header.label}:</Typography>
                      <Typography variant="body2">
                        {listItem[header.id] || "Not Provided"}
                      </Typography>
                  </Box>
                ))}
                {isKycDisplayed && (
                  <Box className="cardContent">
                    <Typography variant="body1">KYC Status:</Typography>
                    <Typography variant="body2">
                      {listItem.kyc_status}
                    </Typography>
                  </Box>
                )}
                {active && 
                    <Button
                      variant="outlined"
                      className={`marginAll ${this.getButtonClassName('Status', listItem.status)}`}
                      data-test-id='status-button'
                    >
                      {listItem.status}
                    </Button>
                }
                {this.getPandingCardData(listItem, actions, active)}
                {this.getCancelledCardData(listItem, actions, active)}           
                {this.getBookedCardData(listItem, actions, active)}
              </CardContent>
            </Card>
          </Grid>
        )})}
      </Grid>
    );
  }

   renderPaginationAndSummary() {
    const { totalCount, totalPages } = this.props;
    const { currentPage } = this.state;
    const startIndex = (currentPage - 1) * 6 + 1;    
    const endIndex = currentPage * 6 > totalCount ? totalCount : currentPage * 6;
    return (
      <>
      {this.state.needSupport ?
          <Box >
            <Grid container spacing={1} >
              <Grid item lg={12} md={12} sm={12} style={{ display: "flex", justifyContent: 'left', paddingTop: "50px", gap: 0 }}>
                <Typography className="needText">Need reservation support?</Typography>
                <Typography className="needText" style={{ color: '#4F9FF8', fontWeight: 700, marginLeft: '10px', cursor:'pointer' }} onClick={() => this.setState({ openModal: true })}>Contact us</Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} className="pagination">
                <Pagination
                  data-test-id="pagination"
                  count={Math.ceil(totalPages)}
                  siblingCount={0}
                  page={currentPage}
                  onChange={this.handlePageChange}
                />
                <Typography variant="subtitle1">
                  {startIndex} - {endIndex} of {totalCount} results
                </Typography>
              </Grid>
            </Grid>
          </Box>
      :
      <Box className="pagination">
        <Pagination
          data-test-id="pagination"
          count={Math.ceil(totalPages)}
          siblingCount={0}
          page={currentPage}
          onChange={this.handlePageChange}
        />
        <Typography variant="subtitle1">
          {startIndex} - {endIndex} of {totalCount} results
        </Typography>
      </Box>
      }
      </>  
    );
  }
}

// Customizable Area End
