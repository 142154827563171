import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { v4 as uuidv4 } from "uuid";
import { getStorageData } from "../../../framework/src/Utilities";
import { getLastPartOfURL, sendAPIRequest } from "../../../components/src/utils";
import { DockDetail } from "./DockBookingController.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
      listedDockDetailsData: DockDetail;
      openLoginModal: boolean;
      errorMsg: string;
      isMoreFeatures: boolean;
      isMoreAddons: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ManageListingDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
    // Customizable Area Start
    getlistedDockDetailsCallId: string = "";
    fetchListingDetailsApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      this.subScribedMessages = [
        // Customizable Area Start
        getName(MessageEnum.SessionResponseMessage),
        getName(MessageEnum.NavigationPayLoadMessage),
        getName(MessageEnum.RestAPIResponceMessage),
        // Customizable Area End
      ];

      this.state = {
        // Customizable Area Start
        listedDockDetailsData: {} as DockDetail,
        openLoginModal: false,
        errorMsg: "",
        isMoreFeatures: false,
        isMoreAddons: false,
        // Customizable Area End
      };
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

      // Customizable Area Start
      const blockId = uuidv4();
      this.send = (message) => runEngine.sendMessage(blockId, message);
      // Customizable Area End
    }

    async receive(from: string, message: Message) {
      runEngine.debugLog("Message Recived", message);
      // Customizable Area Start
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.apiSuccessCallBackController(apiRequestCallId, responseJson);
      // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
      super.componentDidMount();
      this.getListedDockDetails();
      const url = new URL(document.baseURI);
      const shareUrl = url.searchParams.get("url");
      if(shareUrl) {
        this.fetchListingDetails(shareUrl);
      }
    }

    apiSuccessCallBackController = (
      apiRequestCallId: string,
      responseJSON: Record<string, unknown>
    ) => {
      const successCallbackMap = {
        [this.getlistedDockDetailsCallId]: this.handleListingDataAPIResponse,
        [this.fetchListingDetailsApiCallId]: this.handleListingDetailsAPIResponse,
      };
  
      if (apiRequestCallId) {
        const successCallback: (responseJSON: Record<string, unknown>) => void =
          successCallbackMap[apiRequestCallId];
        !!successCallback && successCallback(responseJSON);
      }
    };

    getListedDockDetails = async () => {
      this.setState({ errorMsg: "" });
      const token = await getStorageData("token");
      this.getlistedDockDetailsCallId = sendAPIRequest(
        `${configJSON.urlGetDockListingDetails}/${getLastPartOfURL().url}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            token,
          },
        }
      );
    };

    fetchListingDetails = (shareUrl: string) => {
      this.fetchListingDetailsApiCallId = sendAPIRequest(
        shareUrl,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    };

    handleListingDataAPIResponse = (
      responseJSON: Record<string, unknown>
    ) => {
      const dockId = getLastPartOfURL().url || "-1";
      const response = responseJSON as {
        data?: { id: string; type: string; attributes: DockDetail };
        errors?: string[];
      };
      if (response.data) {
        const listedDockDetailsData = response.data?.attributes;
        this.setState({
          listedDockDetailsData
        });
      } else if(response.errors) {
        this.setState({
          errorMsg: response.errors[0]
        })
      }
    };

    handleListingDetailsAPIResponse = (responseJSON: Record<string, unknown>) => {
      const response = responseJSON as {
        data?: { id: string; type: string; attributes: DockDetail };
        errors?: string[];
      };
      if (response.data) {
        const listedDockDetailsData = response.data.attributes;
        this.setState({
          listedDockDetailsData
        });
      } else if(response.errors) {
        this.setState({
          errorMsg: response.errors[0]
        })
      }
    };

    onBackBtnClick = () => {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "ManageListing");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }
  
    handleEditBtnClick = async (route: string, dockId: number) => {
      const token = await getStorageData("token");
      if(!token) {
        this.setState({ openLoginModal: true });
        return;
      }
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), route);
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationScreenNameMessage), dockId);
      this.send(message);
    };

    handleMoreFeatures = () =>{
      this.setState({ isMoreFeatures: !this.state.isMoreFeatures})
    };

    handleMoreAddons = () => {
      this.setState({ isMoreAddons: !this.state.isMoreAddons})
    };
    // Customizable Area End
  }