// Customizable Area Start
import React from "react";
import { Box, Button, Grid, Typography, styled } from "@material-ui/core";
import SettingsPaymentController, {
  Props,
} from "./SettingsPaymentController.web";
import Settings2 from "../../settings2/src/Settings2.web";
import Toast from "../../../components/src/Toast";
import StripeCardForm from "../../../components/src/StripeCardForm.web";
import { getCardIcon, getLastPartOfURL } from "../../../components/src/utils";

const assets = require("./assets");
const configJSON = require("./config");

const successScreen = (timer: number) => {
  return (
    <Box className="bodyContent successBox">
      <img src={assets.yayIcon} className="successImg" />
      <Typography variant="body1" className="fontWeight600">
        {configJSON.successPaymentMsg}
      </Typography>
      <Typography variant="body1">
        {configJSON.redirectionMsg} <b className="primaryText">{timer} seconds</b>.
      </Typography>
    </Box>
  );
};

const failScreen = (onTryAgainClick: () => void) => {
  return (
    <Box className="bodyContent successBox">
      <img src={assets.oopsIcon} className="successImg" />
      <Typography variant="body1" className="fontWeight600">
        {configJSON.failPaymentMsg}
      </Typography>
      <Button
        data-test-id="tryAgainBtn"
        variant="contained"
        color="primary"
        className="textTransormInitial"
        onClick={onTryAgainClick}
      >
        {configJSON.tryAgainBtnText}
      </Button>
    </Box>
  );
};
// Customizable Area End

export default class SettingsPayment extends SettingsPaymentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const visibleScreen =
      this.state.accountStatus === "done"
        ? successScreen(this.state.successTimer)
        : failScreen(this.getOnboardingAccountData);

    return (
      <Settings2 navigation={this.props.navigation} id={this.props.id}>
        <Wrapper data-test-id="mainWrapper">
          {this.state.accountStatus ? (
            visibleScreen
          ) : (
            <Box className="bodyContent">
              <Box className="responseMsgContainer">
                {this.state.successMsg && (
                  <Toast
                    data-test-id="successToast"
                    message={this.state.successMsg}
                    type="success"
                    onClose={this.handleSuccessMsgClose}
                  />
                )}
                {this.state.errorMsg ? (
                  <Toast
                    data-test-id="toast"
                    message={this.state.errorMsg}
                    type="error"
                  />
                ) : null}
              </Box>
              <Grid container>
                <Grid item md={12} xs={12} sm={12} className="viewBlock">
                  {!this.state.isAddCardFormOpen ? (
                    <Box style={{ minHeight: "35vh" }}>
                      <Typography
                        variant="body1"
                        className="fontWeight600"
                        data-test-id="titleText"
                      >
                        {this.getTitle()}
                      </Typography>
                      <Typography variant="body1" className="padding10">
                        {this.getInfoText()}
                      </Typography>
                      <Button
                        data-test-id="addNewCardBtn"
                        variant="contained"
                        color="primary"
                        className="textTransormInitial"
                        onClick={
                          getLastPartOfURL().role === "host"
                            ? this.getOnboardingAccountData
                            : this.handleAddCardFormOpen
                        }
                      >
                        {this.getAddBtnText()}
                      </Button>
                      {this.state.savedCardList.length
                        ? this.state.savedCardList.map((cardItem) => (
                            <Box
                              data-test-id="savedCard"
                              className="borderCardItem justifySpaceBetween marginBottom10"
                              key={cardItem.id}
                            >
                              <Box className="flexItems">
                                <img
                                  className="cardLogo"
                                  src={getCardIcon(cardItem.type || "")}
                                  alt={cardItem.type || ""}
                                  loading="lazy"
                                />
                                <Box className="flexItemsCol">
                                  <Typography
                                    variant="body1"
                                    className="fontWeight600 textTransormCapitalize"
                                  >
                                    {cardItem.type}{" "}
                                    {configJSON.cardNumberPlaceholder}
                                    {cardItem.last4}
                                  </Typography>
                                  <Typography variant="subtitle1">
                                    {configJSON.expirationDateText} -{" "}
                                    {cardItem.expiry_date}
                                  </Typography>
                                </Box>
                              </Box>
                              <Typography
                                data-test-id="removeCardBtn"
                                variant="body1"
                                className="fontWeight600"
                                style={{ cursor: "pointer" }}
                                onClick={() => this.removeCardById(cardItem.id)}
                              >
                                {configJSON.removeText}
                              </Typography>
                            </Box>
                          ))
                        : null}
                    </Box>
                  ) : (
                    <Box>
                      <Typography variant="h5">
                        {configJSON.addNewCardText}
                      </Typography>
                      <StripeCardForm
                        actionBtnRequired
                        saveBtnText={this.getSaveBtnText()}
                        cancelBtnText={configJSON.cancelBtnText}
                        handleResponse={this.addNewCard}
                        onReset={this.handleReset}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
        </Wrapper>
      </Settings2>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
  "& .bodyContent": {
    margin: "0 200px 100px 50px",
  },
  "& .successBox": {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  "& .successImg": {
    width: "25%",
    height: "auto",
  },
  "& .primaryText": {
    color: "#4F9FF8",
  },
  "& .viewBlock": {
    margin: "12px 0",
  },
  "& .fontWeight600": {
    fontWeight: 600,
  },
  "& .padding10": {
    padding: "10px 0 0 0",
  },
  "& .marginBottom10": {
    marginBottom: "20px",
  },
  "& .textTransormInitial": {
    textTransform: "initial",
  },
  "& .textTransormCapitalize": {
    textTransform: "capitalize",
  },
  "& .borderCardItem": {
    display: "flex",
    gap: "5px",
    padding: "15px",
    border: "1px solid #E2E8F0",
    borderRadius: "8px",
  },
  "& .justifySpaceBetween": {
    justifyContent: "space-between",
  },
  "& .flexItems": {
    display: "flex",
    gap: 10,
    alignItems: "center",
  },
  "& .cardLogo": {
    width: "24px",
    height: "24px",
  },
  "& .flexItemsCol": {
    display: "flex",
    flexDirection: "column",
  },
  "& .responseMsgContainer": {
    margin: "-60px 0 30px 0",
  },
  [theme.breakpoints.down(1030)]: {
    "& .bodyContent": {
      margin: "0 150px 0 50px",
    },
  },
  [theme.breakpoints.down(950)]: {
    "& .bodyContent": {
      margin: "0 100px 0 30px",
    },
  },
  [theme.breakpoints.down(870)]: {
    "& .bodyContent": {
      margin: "0 40px 0 30px",
    },
  },
  [theme.breakpoints.down(740)]: {
    "& .bodyContent": {
      margin: "0 10px 0 30px",
    },
  },
  [theme.breakpoints.down(500)]: {
    "& .bodyContent": {
      margin: "0 10px 0 15px",
    },
  },
}));
// Customizable Area End
