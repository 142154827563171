import React from "react";
// Customizable Area Start
import {
  ThemeProvider,
  Box,
  Container,
  AppBar,
  createTheme,
  Button,
  Typography,
  InputAdornment,
  InputBase,
  Divider,
  Snackbar,
  SnackbarContent,
} from '@material-ui/core';
import { styled } from '@mui/system';
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { imgHeaderLogo, FooterImage } from "./assets";

const theme = createTheme({
  palette: {
      primary: {
          main: "#4F9FF8",
      },
  },
  overrides: {
      MuiTypography: {
          h5: {
              fontFamily: "Josefin Sans",
              fontSize: "1.2rem",
              fontWeight: 700,
              color: "#0F172A",
          },
          body1: {
              fontFamily: "Outfit",
              fontSize: "1rem",
              fontWeight: 400,
              color: "#64748B",
              textTransform: "none",
          },
          subtitle1: {
              fontFamily: "Outfit",
              fontSize: "0.9rem",
              fontWeight: 400,
              color: "#64748B",
          },
        colorTextSecondary:{
          color: "#0F172A",
          fontWeight: 700, 
          fontSize:'48px',
        }
      },  
      MuiDivider: {
          root: { margin: "15px 0" },
      },
      MuiButton: {
          root: {
              borderRadius: "8px",
              padding: "16px 32px",
              margin: "10px 0 30px 0",
          },
          containedPrimary: {
              fontFamily: "Outfit",
              fontSize: "16px",
              fontWeight: 700,
              color: "white",
              textTransform: "capitalize",
          },
      },
  },
});

// Customizable Area End

import DockListingBasePriceController, {
  Props,
  configJSON,
} from "./DockListingBasePriceController.web";

export default class DockListingBasePrice extends DockListingBasePriceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {serviceFee, basePayPrice, errorMsg} = this.state;    
    const basePayPrices = basePayPrice || 0;
    const guestTotals = basePayPrices + serviceFee;
    const earnings = basePayPrice - serviceFee
    return (
      <ThemeProvider theme={theme}>
        <Wrapper data-test-id="calendarModalWrapper">
          <Container maxWidth="lg">
            <Snackbar
              data-test-id="errorSnackbar"
              open={errorMsg.length > 0}
              autoHideDuration={3000}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              onClose={() => this.setState({ errorMsg: "" })}
            >
              <SnackbarContent
                message={errorMsg}
                style={{
                  backgroundColor: '#DC2626',
                  color: 'white',
                  fontWeight: 'bold',
                }}
              />
            </Snackbar>
            <AppBar position="relative" elevation={0} className="headerContent">
              <img src={imgHeaderLogo} className="logoContent" />
            </AppBar>
            <Button startIcon={<ArrowBackIosRoundedIcon />} style={{ textTransform: "none" }} className="backButton"
              onClick={() => this.handleNavigation("DockListingShowOff")}
            >Back</Button>
            <Box className="bodyContent">
              <Typography className="basePrice">
              {configJSON.basePrice}
              </Typography>
              <Typography className="featureText" style={{margin:'16px 0 20px 0'}}>
                {configJSON.basePriceDesc}
              </Typography>
              <InputBase
                value={0 | this.state.basePayPrice}
                className="inputText"
                data-test-id="base-price"
                inputProps={{
                  'data-testId': 'base-price',
                  style: { display: 'flex', alignItems: "center", justifyContent: "center", marginTop:'10px',}
                }}
                placeholder="Enter base price"
                onChange={this.handleBasePriceCharge}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
              {this.state.basePriceErrMsg && (
                <div className='errMsg'>
                  {this.state.basePriceErrMsg}
                </div>
              )}
              <Box className="priceBox">
                <div className="priceBox1">
                  <Typography className="featureText" style={{ fontSize: '14px' }}>
                    {configJSON.basePayPrice}
                  </Typography>
                  <Typography className="featureText" style={{fontSize: '14px'}}>
                    ${basePayPrice > 1 ? basePayPrice : 0}
                  </Typography>
                </div>
                <div className="priceBox1" style={{marginTop:'12px'}}>
                  <Typography className="featureText" style={{ fontSize: '14px' }}>
                    {configJSON.serviceFee}
                  </Typography>
                  <Typography className="featureText" style={{fontSize: '14px'}}>
                    ${serviceFee.toFixed(0)}
                  </Typography>                 
                </div>
                <Divider style={{margin:'12px 0'}}/>
                <div className="priceBox1"> 
                 <div>
                    <Typography className="featureText">
                      {configJSON.guestTotal}
                    </Typography>
                    <Typography className="featureText" style={{color: '#94A3B8'}}>
                      {configJSON.beforeTaxes}
                    </Typography>
                  </div>
                  <Typography className="basePrice" style={{fontSize: '24px', fontFamily:'Josefin Sans'}}>
                    ${guestTotals}
                  </Typography> 
                </div>
              </Box>
              <Box className="earnBox">
                <Typography className="featureText">
                  {configJSON.youEarn}
                </Typography>
                <Typography className="basePrice" style={{ fontSize: '24px', fontFamily: 'Josefin Sans' }}>
                  ${earnings >= 1 ? earnings : 0} 
                </Typography>
              </Box>
              <Button variant="contained" color="primary" data-testId="nextBtn" className='submit'
                onClick={this.getProfileDetails}
              >
                Submit
              </Button>
            </Box>
          </Container>
          <Box className="progressBar">
            <img alt="progress-bar" src={FooterImage} />
          </Box>
        </Wrapper>
      </ThemeProvider>  
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
  "& .errMsg":{ color: 'red', fontSize:'12px', marginTop:'10px', marginBottom:'20px' },
  "& .numberOf": {
    width: "375px",
    '@media (max-width: 450px)': {
      width: "100%",
    },
    height: "44px",
    padding: "16px 0 16px 16px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
  },
  "& .parkingText": {
    marginTop: "16px",
    marginBottom: "4px",
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Outfit",
    color: "#0F172A"
  },
  "& .backButton": {
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Outfit",
    color: "#64748B",
    marginTop:"30px",
  },
  "& .featureText": {
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Outfit", color: "#0F172A",    
  },
  '& .basePrice': {
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Outfit", color: "#0F172A"
  },
  '& .submit': {
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: "Outfit", color: "#FFFFFF",
    marginTop: "40px", width:'100px', height:'56px'
  },
  '& .inputText': {
    fontWeight: 700,
    fontSize: "48px",
    fontFamily: "Josefin Sans", color: "#0F172A",
    width: "120px",
    padding: 0,
    borderBottom: "1px solid #CBD5E1",
  },
  '& .priceBox': {
    border: "1px solid #E2E8F0",
    borderRadius: '8px',
    marginTop:"25px",
    padding:'32px 12px',
  }, 
  '& .priceBox1': {
    display: 'flex', justifyContent: "space-between",
  },
  '& .earnBox': {
    border: "1px solid #E2E8F0",
    borderRadius: '8px',
    marginTop:"24px",
    padding:'16px 12px',
    display: 'flex', 
    justifyContent: "space-between",
    alignItems: 'center',
  },
  "& .headerContent": {
    width: "100%", height: "auto", backgroundColor: "#FFFFFF", padding: "10px 0"
  },
  "& .logoContent": {
    width: "20%",
    height: "auto",
    minWidth: "175px",
  },
  "& .bodyContent": {
    margin: "-65px 240px 178px 240px",
    '@media (max-width: 900px)': {
      margin: "0 10px",
    },
  },
  "& .addressBox": {
    margin: 0,
  }, 
  "& .progressBar": {
    width: "100%",
  },
  "& .progressBar img": {
    width: "100%",
  }
}));
// Customizable Area End
