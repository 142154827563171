import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  getLastPartOfURL,
  sendAPIRequest,
} from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import ReactDatePicker from "react-datepicker";
import Toast from "../../../components/src/Toast";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FlagOutlinedIcon from "@material-ui/icons/FlagOutlined";
import copy from "copy-to-clipboard";
import { format } from "date-fns";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";

export const configJSON = require("./config");
const assets = require("./assets");

interface Prices {
  per_day: string;
  per_Week: string;
  per_month: string;
}
interface DockAddOn {
  id: number;
  add_on_id: number;
  dock_id: number;
  name: string;
  price: number;
}
interface Feature {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}
interface Host {
  id: number;
  name: string;
  rating: number;
  reviews: number;
  avatar: string;
}

interface Availability {
  [key: string]: number[];
}
interface RulesInstuction {
  rules: { type: string; rule: string; }[];
  instructions: { type: string; rule: string; }[];
}
export interface DockDetail {
  id: number;
  latitude: string;
  dockId: number;
  longitude: string;
  address: string | null;
  docking_length: number;
  rental_reason: string;
  docking_width: number;
  water_depth:number;
  listing_title: string;
  about: string;
  access: string;
  parking_space: number;
  max_boats: number;
  max_guests: number;
  allow_pets: boolean;
  allow_smoking: boolean;
  allow_liveaboards: boolean;
  rules_and_cautions: string;
  has_security_camera: boolean;
  has_animals: boolean;
  reservation_type: string;
  cancellation_policy: string;
  base_price: string;
  locality: string | null;
  administrative_area_level_1: string | null;
  administrative_area_level_2: string | null;
  state_short_name: string | null;
  step: number;
  features: Feature[];
  account_id: number;
  lake: { id: number };
  dock_type: string;
  listing_type: string;
  images: string[];
  dock_add_ons: DockAddOn[];
  service_fee: number;
  guest_total: string;
  prices: Prices;
  host: Host;
  blocked_dates: Availability;
  favourite_id: number | null;
  weekend_price: number;
  dock_price?: number;
  price?: number;
  total_add_ons_price?: number;
}
interface ISelectedDetails {
  checkin_date: Date;
  checkout_date: Date;
  guests: string;
  addons: {id: number; add_on_id:number; name: string; price: number }[];
}

interface Reviews {
  id: string;
  type: string;
  attributes: {
    id: number,
    description: string,
    star: number,
    reviewer: {
      name: string,
      avatar: string
    }
  };
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export enum BookingPage {
  Booking,
  Details,
  Payment,
  Booked,
}

interface S {
  // Customizable Area Start  
  loginModal: boolean;
  showRules: boolean;
  isMoreFeatures: boolean;
  isMoreAddons: boolean;
  isExpanded :boolean;
  isMoreDesc: number;
  showAllReviews: boolean;
  reviews:Reviews[];
  total_reviews: number;
  overall_rating: number;
  bookingPage: BookingPage;
  isCheckin: boolean;
  isCheckout: boolean;
  isLoading: boolean;
  pendingDockId: number;
  selectedDetails: ISelectedDetails;
  errorMsg: string;
  errorResMsg: boolean;
  dateStrings: Date[];
  start_date: Date;
  end_date: Date;
  dockBookingData:{
    id:string;
    type:string;
    attributes:DockDetail
  };
  rulesInstuction: RulesInstuction;
  timerFlag: boolean;
  timer: number;
  shareFlag: boolean;
  shareUrl: string;
  kycModal: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DockBookingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  timerId: any = null;
  addToFavoritesApiCallId: string = "";
  removeFromFavoritesApiCallId: string = "";
  draftDocksAPICallId: string = "";
  reservationAPICallId: string = "";
  createAPICallId: string = "";
  reservationPutAPICallId: string = "";
  ratingsAndReviewsAPICallId: string = "";
  generateShareUrlApiCallId: string = "";
  sharedDetailsApiCallId: string = "";
  getReservationDetailsApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start      
      loginModal: false,
      showRules: false,
      isMoreFeatures: false,
      isMoreAddons: false,
      isExpanded : false,
      isMoreDesc: -1,
      errorResMsg:false,
      showAllReviews: false,
      total_reviews: 0,
      overall_rating: 0,
      reviews: [], 
      rulesInstuction: {
        rules: [
          { type: "time", rule: "Check-in: After 4:00 PM" },
          { type: "time", rule: "Checkout:  10:00 AM" },
          { type: "self-checkin", rule: "Self check-in with lockbox" },
          {
            type: "cart",
            rule: "Not suitable for infants (under 2 years)",
          },
          { type: "smoke", rule: "No smoking" },
          { type: "pets", rule: "No pets" },
          { type: "events", rule: "No parties or events" },
        ],
        instructions: [
          {
            type: "clean",
            rule: "Committed to enhanced cleaning process. Show more",
          },
          {
            type: "social-distancing",
            rule: "Daydocker social-distancing and other COVID-19-related guidelines apply",
          },
          { type: "air-alarm", rule: "Carbon monoxide alarm" },
          { type: "smoke-alarm", rule: "Smoke alarm" },
          {
            type: "payment",
            rule: "Security Deposit - if you damage the dock, you may be charged up to $566",
          },
        ],
      },
      dockBookingData: {
        id: '',
        type: '',
        attributes: {
          id: 0,
          dockId:0,
          latitude: "",
          rental_reason: "",
          longitude: "",
          address: null,
          docking_length: 0,
          docking_width: 0,
          water_depth: 0,
          listing_title: "",
          about: "",
          access: "",
          parking_space: 0,
          max_boats: 0,
          max_guests: 0,
          allow_pets: false,
          allow_smoking: false,
          allow_liveaboards: false,
          rules_and_cautions: "",
          has_security_camera: false,
          has_animals: false,
          reservation_type: "",
          cancellation_policy: "",
          base_price: "0.0",
          locality: null,
          administrative_area_level_1: null,
          administrative_area_level_2: null,
          state_short_name: null,
          step: 0,
          features: [],
          account_id: 0,
          lake: { id: 0 },
          dock_type: "",
          listing_type: "",
          images: [],
          dock_add_ons: [],
          service_fee: 0.0,
          guest_total: "0.0",
          prices: {
            per_day: "0.0",
            per_Week: "0.0",
            per_month: "0.0"
          },
          host: {
            id: 0,
            name: "",
            rating: 0.0,
            reviews: 0,
            avatar: '',
          },          
          blocked_dates: {},
          favourite_id: null,
          weekend_price: 0,
        }
      },
      dateStrings: [],
      start_date: new Date(),
      end_date: new Date(),
      bookingPage: BookingPage.Booking,
      isCheckin: false,
      isCheckout: false,      
      selectedDetails: {
        checkin_date: new Date(),
        checkout_date: new Date(new Date().setDate(new Date().getDate() + 1)),
        guests: "2",
        addons: [],
      },
      pendingDockId: 0,
      isLoading: false,
      errorMsg: "",
      timerFlag: false,
      timer: 1200,
      shareFlag: false,
      shareUrl: "",
      kycModal: false,
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const url = new URL(document.baseURI);
    const booking = url.searchParams.get("booking");
    const bookingUrl = url.searchParams.get("url");
    if(booking !== "pending" && booking !== "confirm") {
      this.getDraftDocksData() 
      this.getRatingsAndReviews() 
    }
    if(booking === "pending") {
      this.setState({
        bookingPage: BookingPage.Booked,
      });
      this.getReservationDetails();
    }
    if(booking === "confirm" && bookingUrl) {
      this.fetchSharedDetailsApiCall(bookingUrl);
      this.setState({
        bookingPage: BookingPage.Booked,
      })
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    // Customizable Area Start
    this.apiSuccessCallBackController(apiRequestCallId, responseJson);
    if(new URL(document.baseURI).searchParams.get("booking") !== "pending") {
      this.dockDraftDataApi(message)
      this.createReservationApi(message)
      this.newCreateResponse(message)
      this.updateReservationApi(message)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps: Props, prevState: S) {
    if (this.state.timerFlag && !this.timerId) {
      this.timerId = setInterval(() => {
        this.setState(prevState => {
          if (prevState.timer > 0) {
            return { timer: prevState.timer - 1 };
          } else {
            return { timer: 0 };
          }
        });
      }, 1000);
    }
  }

  componentWillUnmount(): any {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }

  fetchSharedDetailsApiCall = async (shareUrl: string) => {
    this.sharedDetailsApiCallId = sendAPIRequest(
      shareUrl,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  dockDraftDataApi = (message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiDraftReqCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const draftJsonData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    
      if (apiDraftReqCallId === this.draftDocksAPICallId) {
        const dockDetail = draftJsonData.data;
        this.setState({
          dockBookingData: dockDetail
        }) 
        const blockedDates = dockDetail.attributes.blocked_dates;
        blockedDates && Object.keys(blockedDates).some(key => blockedDates[key] && blockedDates[key].length > 0)&& this.formatAvailabilityDates(blockedDates)     
      }
    }
  } 
  getDraftDocksData = async () => {
    const token = await getStorageData("token");
    const headers = token ? {
      "Content-Type": "application/json",
      token,
    } : {
      "Content-Type": "application/json",
    };
    const getValidationsMsgs = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.draftDocksAPICallId = getValidationsMsgs.messageId
    getValidationsMsgs.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchDockPath}/${getLastPartOfURL().url}`)
    getValidationsMsgs.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsgs.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDockListingDetailsApiMethodType
    );
    runEngine.sendMessage(getValidationsMsgs.id, getValidationsMsgs);
  }

  formatAvailabilityDates = (blocked: Availability) => {
    const dateStrings: Date[] = [];
    
    for (const [keys, dates] of Object.entries(blocked)) {
      const [month, year] = keys.split("-");

      const formattedDates = dates.map((date) =>
        new Date(`${month}/${String(date).padStart(2, "0")}/${year}`)
      );
      
      dateStrings.push(...formattedDates);
    }
    this.setState({
      dateStrings,
      start_date: dateStrings[0] > new Date() ? dateStrings[0] : new Date(),
      end_date: dateStrings[dateStrings.length - 1],
    })    
  };

  handleBackBtnClick = () => {
    this.setState((prevState) => {
      return {
        bookingPage:
          prevState.bookingPage === BookingPage.Details
            ? BookingPage.Booking
            : BookingPage.Details,
      };
    });
  };

  goToNextPage = () => {
    this.setState((prevState) => {
      return {
        bookingPage: BookingPage.Payment,
        kycModal: prevState.bookingPage === BookingPage.Payment ? true : false,
      };
    });
  };

  formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  handleOpenShareModal = async () => {
    if(!this.state.shareFlag) {
      const token = await getStorageData("token");
      if(!token) {
        this.setState({ loginModal: true });
        return;
      }
      this.generateShareUrlApiCallId = sendAPIRequest(
        `bx_block_order_reservations/reservation_services/${this.state.dockBookingData.attributes.id}/share`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            token,
          },
        }
      );
    } else {
      this.setState({
        shareFlag: false,
      })
    }
  };

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.addToFavoritesApiCallId]: this.handleAddFavoriteApiCall,
      [this.removeFromFavoritesApiCallId]: this.handleRemoveFavoriteApiCall,
      [this.ratingsAndReviewsAPICallId]: this.handleRatingsAndReviewsRes,  
      [this.generateShareUrlApiCallId]: this.handleShareUrlAPIResponse,
      [this.sharedDetailsApiCallId]: this.handleSharedDetailsAPIResponse,
      [this.getReservationDetailsApiCallId]: this.handleReservationDetailsApiCall,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  addRemoveFromFavorites = async (dockId: number | null) => {
    this.setState({ errorMsg: "" });
    const token = await getStorageData("token");
    if(!token) {
      this.setState({ loginModal: true });
      return;
    }
    const dock_id = getLastPartOfURL().url;
    if(dockId) {
      this.removeFromFavoritesApiCallId = sendAPIRequest(
        `bx_block_favourites/favourites/${dockId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            token,
          },
        }
      );
    } else {
      this.addToFavoritesApiCallId = sendAPIRequest(
        `bx_block_favourites/favourites`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token,
          },
          body: {
            data: {
              dock_id
            },
          },
        }
      );
    }
  };

  getRatingsAndReviews = async () => {
    this.setState({ errorMsg: "" });
    const token = await getStorageData("token");
    if(!token) {
      return;
    }
    const dockId = getLastPartOfURL().url;
    this.ratingsAndReviewsAPICallId = sendAPIRequest(
      `${configJSON.reviewsPath}${dockId}&per=20`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token,
        },
      }
    );
  };

  getReservationDetails = async () => {
    const reservationId = getLastPartOfURL().url;
    const token = await getStorageData("token");
    this.getReservationDetailsApiCallId = sendAPIRequest(
      `bx_block_order_reservations/reservation_services/${reservationId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token
        },
      }
    );
  };

  handleRatingsAndReviewsRes = (
    responseJSON: Record<string, unknown>
  ) => {
    const response = responseJSON as {
      data: Reviews[];
      total_reviews: number;
      overall_rating: number;
    };
    if (response) {
      this.setState({
        reviews: response.data,
        total_reviews: response.total_reviews,
        overall_rating: response.overall_rating,
      });
    }
  };
  handleAddFavoriteApiCall = (
    responseJSON: Record<string, unknown>
  ) => {
    if (this.handleErrorResponse(responseJSON)) return;
    const response = responseJSON as {
      meta?: { message: string };
      data?: { id: string; type: string; attributes: object }[];
    };
    if (response.data) {
      this.getDraftDocksData();
    }
  };

  handleRemoveFavoriteApiCall = (
    responseJSON: Record<string, unknown>
  ) => {
    if (this.handleErrorResponse(responseJSON)) return;
    const response = responseJSON as { message?: string };
    if (response.message) {
      this.getDraftDocksData();
    }
  };

  handleShareUrlAPIResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;
    const response = responseJSON as {
      url?: string;
      message?: string;
    };
    if (response.url && response.message) {
      this.setState({
        shareFlag: true,
        shareUrl: response.url,
      });
    }
  };

  handleSharedDetailsAPIResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;
    const response = responseJSON as {
      data?: { id: string; type: string; attributes: object };
    };
    if (response.data) {
      const dockDetails: any = response.data.attributes;
      this.setState({
        selectedDetails: {
          checkin_date: new Date(dockDetails.slot_start_time),
          checkout_date: new Date(dockDetails.slot_end_time),
          guests: dockDetails.guest_count,
          addons: dockDetails.add_ons,
        },
        dockBookingData: {
          ...this.state.dockBookingData,
          attributes: {
            ...this.state.dockBookingData.attributes,
            listing_title: dockDetails.dock_details.listing_title,
            about: dockDetails.dock_details.about,
            prices: dockDetails.dock_details.prices,
            host: dockDetails.dock_details.host,
            service_fee: dockDetails.dock_details.service_fee,
            cancellation_policy: dockDetails.dock_details.cancellation_policy || "no_hours",
            dockId: dockDetails.dock_listing_id,
          }
        }
      });
    }
  };

  handleReservationDetailsApiCall = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON))
      return;
    const response = responseJSON as {
      data?: { id: string; type: string; attributes: any };
    };
    if (response.data) {
      this.setState({
        dockBookingData: {
          ...this.state.dockBookingData,
          attributes: { 
            ...this.state.dockBookingData.attributes,
            ...response.data.attributes,
            dockId: this.state.dockBookingData.attributes.id
          },
        },
        selectedDetails: {
          checkin_date: new Date(response.data.attributes.slot_start_time),
          checkout_date: new Date(response.data.attributes.slot_end_time),
          guests: (response.data.attributes.guest_count).toString(),
          addons: response.data.attributes.add_ons || []
        },
      });
    }
  };

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    this.setState({ isLoading: false });

    const { errors } = responseJSON as { errors: { error: string } };
    if (errors) {
      this.setState({ errorMsg: errors.error });
      return true;
    }
    return false;
  };

  handleCopyText = (value: string) => {
    copy(value);
  };

  toggleDatePicker = (type: "in" | "out", isOpen: boolean) => {
    this.setState({
      isCheckin: type === "in" ? isOpen : this.state.isCheckin,
      isCheckout: type === "out" ? isOpen : this.state.isCheckout,
    });
  };

  getNextDay = (date: Date) => {
    const nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);
    return nextDay;
  };

  setSelectedDates = (type: "in" | "out", date: Date | null) => {
    if (date) {
      if (type === "in") {
        this.setState({
          selectedDetails: {
            ...this.state.selectedDetails,
            checkin_date: date,
            checkout_date: this.getNextDay(date),
          },
        });
      } else {
        this.setState({
          selectedDetails: {
            ...this.state.selectedDetails,
            checkout_date: date,
          },
        });
      }
      this.toggleDatePicker(type, false);
    }
  };

  handleGuestChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    event.preventDefault();
    const guests = event.target.value as string;
    if (event.target.value)
      this.setState({
        selectedDetails: {
          ...this.state.selectedDetails,
          guests,
        },
      });
  };

  handleAddons = async (addon: { id: number; add_on_id: number; name: string; price: number }) => {
    const token = await getStorageData("token");
    if(!token) {
      this.setState({ loginModal: true });
      return;
    }
    this.setState((prevState) => {
      const { addons } = prevState.selectedDetails;
      return {
        selectedDetails: {
          ...prevState.selectedDetails,
          addons: addons.some(({ name }) => name === addon.name)
            ? addons.filter(({ name }) => name !== addon.name)
            : [...addons, addon],
        },
      };
    });
  };
  textToShow = (reason:string) => {
    const ellipsis = reason.length > 50 ? '...' : '';   
    return this.state.isExpanded
    ? reason
    : reason.substring(0, 50) + ellipsis;
  }
  descToShow = (desc:string, index: number) => {
    const ellipsis = desc.length > 150 ? '...' : '';   
    return this.state.isMoreDesc === index
    ? desc
    : desc.substring(0, 150) + ellipsis;
  }
  showLessMoreFeatures = () => {
    return this.state.isMoreFeatures ? configJSON.showLessText : configJSON.showMoreText
  };
  showLessMoreAddons = () => {
    return this.state.isMoreAddons ? configJSON.showLessText : configJSON.showMoreText
  };
  handleMoreFeatures = () =>{
    this.setState({ isMoreFeatures: !this.state.isMoreFeatures})
  };
  handleMoreAddons = () => {
    this.setState({ isMoreAddons: !this.state.isMoreAddons})
  };
  displayShowMoreAddons = (index: number) => {
    if (index > 7 && !this.state.isMoreAddons) return true;
  };
  handleToggle = () =>{
    this.setState({ isExpanded: !this.state.isExpanded})
  }
  handleDescription = (index: number) =>{
    this.setState((prevState) => ({
      isMoreDesc: prevState.isMoreDesc === index ? -1 : index,
    }));
  }
  handleRules = () =>{
    this.setState({ showRules: !this.state.showRules})
  }
  showLessMore = () => {
    return this.state.isExpanded ? configJSON.showLessText : configJSON.showMoreText
  }
  showLessMoreDesc = (index: number) => {
    return this.state.isMoreDesc === index ? configJSON.showLessText : configJSON.showMoreText
  }
  showLessMoreReviews = () => {
    return this.state.showAllReviews ? configJSON.showLessReviews : configJSON.showAllText
  }
  showLessMoreRules = () => {
    return this.state.showRules ? configJSON.showLessText : configJSON.showMoreText
  }
  showStateName = (dock1:string | null, dock2:string | null) => {
    return dock1 ? (`${dock1},${dock2}`) : "Not Provided"
  }
  createReservationApi = (message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiResCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if (apiResCallId === this.reservationAPICallId) {  
        if(resJson.data !== null){
          this.updateAPI(resJson.data.attributes.id)
        } else {
          this.newCreateAPI()
        }
      }
    }
  }  
  handleBookingBtnClick = async() => {
    this.setState({ timerFlag: false });
    const dockId = getLastPartOfURL();
    const token = await getStorageData("token");
    if(!token) {
      this.setState({ loginModal: true });
      return;
    }
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.reservationAPICallId = getValMsg.messageId
    getValMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.urlGetItemList}/pending?dock_id=${dockId.url}`)
    getValMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReservationHistoryApiMethodType
    );
    runEngine.sendMessage(getValMsg.id, getValMsg);  
  }
  updateReservationApi = (message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiResCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const resUpdateJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if (apiResCallId === this.reservationPutAPICallId) {  
        if(resUpdateJson.meta === 'Reservation updated successfully'){
          this.setState({
            timerFlag: true,
            bookingPage: BookingPage.Details,
            dockBookingData: {
              ...this.state.dockBookingData,
              attributes: { 
                ...this.state.dockBookingData.attributes,
                ...resUpdateJson.data.attributes,                
                dockId: this.state.dockBookingData.attributes.id
              },
            },
            selectedDetails: {
              checkin_date: new Date(resUpdateJson.data.attributes.slot_start_time),
              checkout_date: new Date(resUpdateJson.data.attributes.slot_end_time),
              guests: (resUpdateJson.data.attributes.guest_count).toString(),
              addons: resUpdateJson.data.attributes.add_ons || []           
            },
          });
        }
      }
    }
  } 
  newCreateResponse = (message:Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiCreateResCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if (apiCreateResCallId === this.createAPICallId) {  
        if (responseJson.errors) {
          this.setState({ errorResMsg: true, timerFlag: false, timer: 1200 });
        }
        if(responseJson.meta === 'Reservation created successfully'){
          this.setState({
            timerFlag: true,
            timer: 1200,
            bookingPage: BookingPage.Details,
            dockBookingData: {
              ...this.state.dockBookingData,
              attributes: { 
                ...this.state.dockBookingData.attributes,
                ...responseJson.data.attributes,
                dockId: this.state.dockBookingData.attributes.id
              },
            },
            selectedDetails: {
              checkin_date: new Date(responseJson.data.attributes.slot_start_time),
              checkout_date: new Date(responseJson.data.attributes.slot_end_time),
              guests: (responseJson.data.attributes.guest_count).toString(),
              addons: responseJson.data.attributes.add_ons || []
            },
          });
        }
      }
    }
  }
  sendReservationAPIRequest = async (
    endpoint: string,
    methodType: string,
    reservationBody: object
  ) => {
    const token = await getStorageData("token");
  
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
  
    const apiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    if (methodType === configJSON.editReservationApiMethodType) {
      this.reservationPutAPICallId = apiMsg.messageId;
    } else {
      this.createAPICallId = apiMsg.messageId;
    }
  
    apiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    apiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    apiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    apiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(reservationBody)
    );
  
    runEngine.sendMessage(apiMsg.id, apiMsg);
  };
  newCreateAPI = async() => {
    const dockId = getLastPartOfURL();
    const addOnsId = this.state.selectedDetails.addons.map(addon => addon.id)

    const reservationBody = {
      "reservation": {
        "slot_start_time":  this.state.selectedDetails.checkin_date.toISOString().slice(0, 10),
        "slot_end_time": this.state.selectedDetails.checkout_date.toISOString().slice(0, 10),
        "guest_count": this.state.selectedDetails.guests,
        "dock_listing_id": dockId.url,
        "add_on_ids": addOnsId
    }
    }
    await this.sendReservationAPIRequest(
          configJSON.urlGetItemList,
          configJSON.secureReservationApiMethodType,
          reservationBody
        );
};

  updateAPI = async(dockId:number) => {
    const addOnsId = this.state.selectedDetails.addons.map(addon => addon.id)
    
    const reservationBody = {
      "reservation": {
        "slot_start_time":  this.state.selectedDetails.checkin_date.toISOString().slice(0, 10),
        "slot_end_time": this.state.selectedDetails.checkout_date.toISOString().slice(0, 10),
        "guest_count": this.state.selectedDetails.guests,
        "add_on_ids": addOnsId
    }
    }
    await this.sendReservationAPIRequest(
          `${configJSON.urlGetItemList}/${dockId}`,
          configJSON.editReservationApiMethodType,
          reservationBody
        );
};
  changeIndate = (date:Date | null) => {
    this.setSelectedDates("in", date);
    this.dayBefore()
  }
  dayBefore = () => {
    const hours = this.state.dockBookingData?.attributes?.cancellation_policy?.split('_')[0];
    const cancellationDeadline = new Date(this.state.selectedDetails.checkin_date.getTime() + parseInt(hours) * 60 * 60 * 1000);
    const day = cancellationDeadline.getUTCDate();
    const month = cancellationDeadline.toLocaleString('en-US', { month: 'short' });
    const year = cancellationDeadline.getUTCFullYear();

    const formattedDate = `${day} ${month} ${year}`;
    return formattedDate;
  }

  getPrice = () => {
    const weekend_price = this.state.dockBookingData.attributes.weekend_price
      ? this.state.dockBookingData.attributes.weekend_price
      : this.state.dockBookingData.attributes.guest_total;
    return this.state.selectedDetails.checkin_date.getDay() === 0 ||
      this.state.selectedDetails.checkin_date.getDay() === 6
      ? weekend_price
      : this.state.dockBookingData.attributes.guest_total
  };

  renderBookingCard = () => {
    const { selectedDetails, isCheckin, isCheckout, errorMsg } = this.state;

    return (
      <Box className="borderedBox">
        <Box className="priceTag">
          <Typography variant="h6">
            ${this.getPrice()}
          </Typography>
          <Typography variant="subtitle2">{configJSON.dayText}</Typography>
        </Box>
        <Box className="detailContent">
          <Box className="checkInOutContainer">
            <Box
              data-test-id="checkinToggleDatepicker"
              className="checkInBox pointer"
              onClick={() => this.toggleDatePicker("in", true)}
            >
              <Typography variant="body2">{configJSON.checkinText}</Typography>
              <Typography variant="subtitle2" data-test-id="checkin-date">
                {format(new Date(selectedDetails.checkin_date), "MM/dd/yyyy")}
              </Typography>
              <ReactDatePicker
                data-test-id="checkin-datepicker"
                className="checkInDatepicker"
                open={isCheckin}
                selected={selectedDetails.checkin_date}
                excludeDates={this.state.dateStrings}
                minDate={new Date()}
                onChange={(date) => this.changeIndate(date)}
                selectsStart
                customInput={<button style={{ display: "none" }} />}
                onClickOutside={() => this.toggleDatePicker("in", false)}
              />
            </Box>
            <Box
              data-test-id="checkoutToggleDatepicker"
              className="checkOutBox pointer"
              onClick={() => this.toggleDatePicker("out", true)}
            >
              <Typography variant="body2">{configJSON.checkoutText}</Typography>
              <Typography variant="subtitle2" data-test-id="checkout-date">
                {format(new Date(selectedDetails.checkout_date), "MM/dd/yyyy")}
              </Typography>
              <ReactDatePicker
                data-test-id="checkout-datepicker"
                className="checkOutDatepicker"
                open={isCheckout}
                selected={selectedDetails.checkout_date}
                onChange={(date) => this.setSelectedDates("out", date)}
                selectsEnd
                startDate={selectedDetails.checkin_date}
                endDate={selectedDetails.checkout_date}
                minDate={this.getNextDay(selectedDetails.checkin_date)}
                excludeDates={this.state.dateStrings}
                customInput={<button style={{ display: "none" }} />}
                onClickOutside={() => this.toggleDatePicker("out", false)}
              />
            </Box>
          </Box>
          <FormControl className="selectGuestStyle">
            <InputLabel>
              <Typography variant="body1">{configJSON.guestsText}</Typography>
            </InputLabel>
            <Select
              data-test-id="guestSelector"
              value={selectedDetails.guests}
              IconComponent={ExpandMoreIcon}
              onChange={this.handleGuestChange}
            >
              {[1, 2, 3, 4].map((guest) => (
                <MenuItem value={guest.toString()} key={guest}>
                  {guest} guests
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {errorMsg && (
          <Toast data-test-id="errorMsg" message={errorMsg} type="error" />
        )}
        <Button
          data-test-id="bookingBtn"
          variant="contained"
          color="primary"
          onClick={() => this.handleBookingBtnClick()}
        >
          {this.state.dockBookingData.attributes.reservation_type ===
          configJSON.reservationTypeBookNow
            ? configJSON.instantBookBtnText
            : configJSON.requestReservationBtnText}
        </Button>
        <Box className="reportBox">
          <Typography variant="subtitle1" className="textDecoration">
            <FlagOutlinedIcon />
            {configJSON.reportListingText}
          </Typography>
          <Typography variant="subtitle1">
            {`You can cancel anytime before ${this.dayBefore()}.`}
          </Typography>
        </Box>
      </Box>
    );
  };
  getHost = (avatar: string) => { 
    return avatar !== null ? (<Avatar src={avatar} />) :
    (<Avatar src={assets.userLogo} style={{opacity:0.7}}/> )
  }
  renderReviews = () => {
    return (
      <>
      {this.state.reviews?.map((review, index) => {
          if (index > 3 && !this.state.showAllReviews) return null;
          return (
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box style={{ borderRadius: '12px', boxShadow: '0 5px 15px rgba(15, 15, 15, 0.12)', transition: 'boxShadow 0.3s ease', padding: '24px',height: '140px' }}>
                <ListItem>
                  <ListItemAvatar>
                    {this.getHost(review.attributes.reviewer.avatar)}
                  </ListItemAvatar>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body1" className="fontWeight600">
                      {review.attributes.reviewer.name}
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      {Array.from({ length: Number(review.attributes.star) }, () => (
                        <img src={assets.reviewLogo} alt="review" loading="lazy" />
                      ))}
                    </div>
                  </div>
                </ListItem>
                <Typography variant="body1" style={{ marginTop: '14px' }}>
                  {this.descToShow(review.attributes.description, index)}
                </Typography>
                {review.attributes.description?.length > 150 && 
                  <Typography
                    variant="body1"
                    className="marginTop10 fontWeight700 textDecorationUnderline"
                    onClick={() => this.handleDescription(index)}
                    data-test-id='show-description'
                  >
                    {this.showLessMoreDesc(index)}
                  </Typography>
                  }
              </Box>
            </Grid>
          )
        })
      }
      </>
    )
  }

  showMoreAddons = () => {
    const dock = this.state.dockBookingData.attributes;
    return dock.dock_add_ons?.length > 8 && (
      <Typography
        variant="body1"
        className="marginTop10 fontWeight700 textDecorationUnderline"
        onClick={this.handleMoreAddons}
        data-test-id='show-addons'
      >
        {this.showLessMoreAddons()}
      </Typography>
    )
  };

  showMoreFeatures = () => {
    const dock = this.state.dockBookingData.attributes;
    return dock.features?.length > 10 && (
      <Typography
        variant="body1"
        className="marginTop10 fontWeight700 textDecorationUnderline"
        onClick={this.handleMoreFeatures}
        data-test-id='show-features'
      >
        {this.showLessMoreFeatures()}
      </Typography>
    )
  };

  renderFeatures = () => {
    const dock = this.state.dockBookingData.attributes;
    return dock.features.length > 0 ? (
      dock.features.map((feature, index) => {
        if (index > 9 && !this.state.isMoreFeatures) return null;
        return (
          <Typography variant="body1" key={index}>
            {feature.name}
          </Typography>
        )})
    ) : (
      <Typography variant="body1" color="textSecondary">
        No Listing Feature is added in dock
      </Typography>
    )
  };

  renderAddons = () => {
    const dock = this.state.dockBookingData.attributes;
    return dock.dock_add_ons && dock.dock_add_ons.length > 0 ? (
      dock.dock_add_ons.map((addon, index) => {
        if(this.displayShowMoreAddons(index)) return null;
        const isSelected = this.state.selectedDetails.addons.some(selectedAddon => 
          selectedAddon.add_on_id === addon.add_on_id &&
          selectedAddon.name === addon.name &&
          selectedAddon.price === addon.price
        );
        return(
        <Box
          key={addon.id}
          className="flexItems justifySpaceBetween"
        >
          <Box className="flexItems gapNone">
            <Checkbox
              data-test-id="addonCheckbox"
              disableRipple
              icon={
                <AddCircleOutlineRoundedIcon
                  style={{ color: "#0F172A" }}
                />
              }
              checkedIcon={
                <CheckCircleOutlineRoundedIcon
                  style={{ color: "#059669" }}
                />
              }
              inputProps={{ 'data-test-id': `check-box` } as React.InputHTMLAttributes<HTMLInputElement> & {
                'aria-label'?: string;
                'data-test-id'?: string;
              }}
              checked={isSelected}
              onChange={() => this.handleAddons(addon)}
            />
            <Typography variant="body1">{addon.name}</Typography>
          </Box>
          <Typography variant="body1">${addon.price}</Typography>
        </Box>
      )})
    ) : (
      <Typography variant="body1" color="textSecondary">
        No Add-ons item is added in dock
      </Typography>
    )
  };

  renderRules = () => {
    const rules = this.state.rulesInstuction.rules.map((ruleItem) => ({
      text: ruleItem.rule,
    }));

    return (
      <>
        {rules.map((rule, index) => {
        if (index > 3 && !this.state.showRules) return null;
        return (
          <ListItem key={index}>
            <ListItemText primary={rule.text} />
          </ListItem>
        )}
        )}
      </>
    );
  };

  renderInstructions = () => {
    const instructions = this.state.rulesInstuction.instructions.map(
      (instructionItem) => ({
        text: instructionItem.rule,
      })
    );

    return (
      <>
        {instructions.map((instruction, index) => {
          if (index > 3 && !this.state.showRules) return null;
        return(
          <ListItem key={index}>
            <ListItemText primary={instruction.text} />
          </ListItem>
        )
        })}
      </>
    );
  };
  // Customizable Area End
}
