import React from "react";
// Customizable Area Start
import { Container, Box, Typography, Grid, ListItem, ListItemAvatar, Avatar, Button, Snackbar, SnackbarContent, Tooltip } from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Header from "../../../components/src/Header";
export const assets = require("./assets");
import { Done } from '@material-ui/icons';

export const theme = createTheme({
  overrides: {
    MuiTypography: {
      subtitle1: {
        fontFamily: "Outfit",
        color: "#64748B",
        fontWeight: 400,
        fontSize: "1rem",
      },
      root: {
        fontFamily: "Josefin Sans",
      },
      h4: {
        fontFamily: "Josefin Sans",
        fontSize: "1.5rem",
        fontWeight: 700,
      },
    },
  },
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#0000ff",
    },
  },
});

import ProfileController, {
  Props,
  configJSON,
} from "./ProfileController.web";
import CustomInput from "../../../components/src/CustomInput.web";
import Footer from "../../../components/src/Footer";

// Customizable Area End

export default class Profile extends ProfileController {
    constructor(props: Props) {
        // Customizable Area Start
    super(props);
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const {profileDetails} = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Wrapper data-test-id="mainWrapper">
        <Container maxWidth="lg">
          <Header
            navigation={this.props.navigation}
            role= {this.state.userRole === 'host' ? 'host' : 'boater'} 
          />
        </Container>
        <Snackbar
          open={this.state.successMsg}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <SnackbarContent
            message='Saved Successfully!.'
            style={{
              backgroundColor: '#ECFDF5',
                color: '#059669',
                fontWeight: 'bold',
            }}
          />
        </Snackbar>      
        <Container maxWidth="lg" style={{ minHeight: "65vh" }}>
          <Typography data-test-id="profileTitle" className="profileText" style={{marginTop:'30px'}}>
            Profile
          </Typography>
          <Box className="mainBox">
            <Grid container spacing={1}>
              <Grid item lg={5} md={5} sm={6} xs={12} className="borderRight">
                <Typography className="hostText" style={{marginBottom: "24px"}}>{this.state.userRole === 'host' ? configJSON.hostInformationText : configJSON.boaterInformationText}</Typography>
                <ListItem disableGutters style={{marginBottom:'15px',}}>
                  <ListItemAvatar>
                    <Avatar src={profileDetails.profile_pic ? profileDetails.profile_pic : assets.userLogo} className="avater" 
                      alt={profileDetails.first_name || ""}/>
                  </ListItemAvatar>
                  <div className="flexItems" >
                    <Typography className="firstName">
                      {profileDetails.first_name || "Not Provided"} {profileDetails.last_name}
                    </Typography>  
                    <Box style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                    {this.state.isEditing ? (
                      <div>
                        <CustomInput
                          data-test-id='editBioInput'
                          value={profileDetails.bio || ''}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    ) : (
                              <Tooltip
                                title={
                                  profileDetails.bio || "Not Provided"
                                }
                                placement="bottom-start"
                                PopperProps={{
                                  style: {
                                    marginTop: '-10px',
                                  },
                                }}
                              >
                                <Typography
                                  className="avtarTexts tooltip"
                                >
                                  {profileDetails.bio || "Not Provided"}
                                </Typography>
                              </Tooltip>
                    )}
                    {!profileDetails.bio && this.state.noBioError && (
                    <div className='errMsg'>
                      {this.state.noBioError}
                    </div>
                    )}
                    <div className="edit">                   
                    {this.state.isEditing ? 
                      <>
                        <Typography data-test-id="saveBtn" className="textSelected" style={{cursor:'pointer'}} onClick={this.handleSaveClick}>
                          Save bio
                        </Typography>
                        <Typography data-test-id="cancelBtn" onClick={this.handleCancelClick} className="textSelected" style={{cursor:'pointer', textTransform:'none'}}>
                          Cancel
                        </Typography>
                      </>
                      : 
                      <Typography data-test-id="editBtn" className="textSelected" style={{cursor:'pointer'}} onClick={this.handleEditClick}>
                        Edit
                      </Typography>
                    } 
                    </div>
                    </Box>
                  </div>
                </ListItem>
              </Grid>
              <Grid item lg={7} md={7} sm={6} xs={12} className="secondGrid">
              <Typography className="hostText">{configJSON.verifiedInformation}</Typography>
                <div className="inboxHeader">
                  <Done/>
                  <Typography className="avtarTexts">{configJSON.email}</Typography>
                </div>
                <div className="inboxHeader">
                  <Done/>
                  <Typography className="avtarTexts">{configJSON.governmentId}</Typography>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Container>          
        <Footer navigation={this.props.navigation} />
        </Wrapper>
      </ThemeProvider>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
  "& .errMsg":{ color: 'red', fontSize:'12px' },
  "& .tooltip":{
    textOverflow: "ellipsis",
    cursor:'pointer',
    overflow: "hidden",
    width: "200px",
    whiteSpace: "nowrap",
    '@media (max-width: 852px) and (min-width: 599px)': {
      width: "100px",     
    },
    '@media (max-width: 440px)': {
      width: "100px",     
    },
  },
  "& .borderRight": {
    borderRight:'1px solid #E2E8F0' ,
    '@media (max-width: 599px)': {
      borderRight: 'none',
      borderBottom:'1px solid #E2E8F0',      
    },
  },
  "& .edit": {
    display:'flex', flexDirection:'row', gap:'20px'
  },
  "& .secondGrid": {
    paddingLeft:'160px', display:'flex',flexDirection:'column', gap:'24px',
    '@media (max-width: 900px)': {
      paddingLeft:'32px',
    },
    '@media (max-width: 599px)': {
      marginTop:'20px',
      paddingLeft:0,
    },
  },
  "& .avater": {
    opacity:0.7, height:'133px', width:'133px', border:'4px solid #B7D8FC'
  },
  "& .profileText": {
    fontFamily:'Josefin Sans',
    fontWeight:700,
    fontSize:'24px'
  },
  "& .firstName": {
    fontFamily:'Josefin Sans',
    fontWeight:700,
    fontSize:'30px',
    '@media (max-width: 700px) and (min-width: 600px)': {
      fontSize:'22px',
    },
    '@media (max-width: 399px)': {
      fontSize:'22px',
    },
  },
  "& .mainBox": {
    padding: "32px",
    marginTop: "24px",
    borderRadius:'8px',
    border:'1px solid #E2E8F0',
    boxShadow:'0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008',    
  },
  "& .hostText": {
    fontFamily:'Outfit',
    fontWeight:700,
    fontSize:'18px',
  },
  "& .flexItems": {
    display:'flex', flexDirection:'column', gap: '8px', marginLeft: '16px'
  },
  "& .avtarTexts": {    
    fontSize: "16px", fontWeight: 400, fontFamily: 'Outfit', color:'#0F172A'
  },
  "& .textSelected": {
    color: "#4F9FF8",
    fontWeight: 700,
    fontSize: "16px", fontFamily: 'Outfit'
  }, 
  "& .inboxHeader": {
    display: "flex",
    alignItems: "center",
    gap: '12px',
  }, 
}));
// Customizable Area End
