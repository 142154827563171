// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Typography,
} from "@material-ui/core";

import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import { ArrowBackIosRounded } from "@material-ui/icons";

const theme = createTheme({
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "Josefin Sans !important",
      },
      body1: {
        fontSize: "24px",
        fontWeight: 400,
      },
    },
  }
});

import JobListingController, {
  Props,
  configJSON,
} from "./JobListingController.web";

class PrivacyPolicy extends JobListingController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const privacyPolicy = this.state.privacyPolicyList;
    return (
      <>
        <ThemeProvider theme={theme}>
          <Wrapper data-test-id="jobListingContainer">
            <Container maxWidth="lg">
              <Header navigation={this.props.navigation} />
              <Box style={{ marginBottom: '100px' }}>
                <div className="iconPlace" style={{ flexDirection: 'row' }}>
                  <ArrowBackIosRounded data-test-id='backBtn' style={{ cursor: 'pointer' }} onClick={() => this.goBack()} />
                  <Typography className="titleText" data-test-id="Frequently" >
                    {configJSON.titlePrivacyPolicy}
                  </Typography>
                </div>
                <Typography className="allText">
                  {privacyPolicy.description ?
                    <Box dangerouslySetInnerHTML={{__html: privacyPolicy.description}} /> :
                    <Typography className="title">No Privacy Policy found</Typography>}
                </Typography>
              </Box>
            </Container>
            <Footer navigation={this.props.navigation} />
          </Wrapper>
        </ThemeProvider>
      </>
    );
  }
}

export default PrivacyPolicy;

const Wrapper = styled('div')(({ theme }) => ({
  "& .title": {
    fontFamily: 'Josefin Sans',
    fontWeight: 700,
    fontSize: '24px',
    justifyContent: 'center',
    marginTop: '47px',
    display: 'flex',
    color: '#94A3B8'
  },
  "& .iconPlace": {
    display: 'flex',
    gap: '14px',
    margin: "50px 0 20px 0",
    marginLeft: '-30px',
    alignItems: 'baseline',
    '@media (max-width: 1300px)': {
      marginLeft: '0px',
    },
  },
  "& .titleText": {
    fontWeight: "700",
    fontSize: "36px",
    fontStyle: 'Josefin Sans',
    letterSpacing: "-0.01em",
    lineHeight: 1.1,
  },
  "& .allText": {
    minHeight: "26vh",
    fontFamily: "Outfit",
    fontSize: "18px",
    color: "#64748B",
    fontWeight: 400,
    marginLeft: '10px',
  },
}));
// Customizable Area End