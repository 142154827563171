import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  Avatar,
  Box,
  Button,
  Divider,
  Typography,
} from "@material-ui/core";
import { BookingPage, DockDetail } from "./DockBookingController.web";
import {
  getLastPartOfURL,
  sendAPIRequest,
} from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";
import { v4 as uuidv4 } from "uuid";
import ChatBubbleOutlineRoundedIcon from "@material-ui/icons/ChatBubbleOutlineRounded";
import ShareRoundedIcon from "@material-ui/icons/ShareRounded";
import { format } from "date-fns";

export const configJSON = require("./config");
const assets = require("./assets");

export interface ISelectedDetails {
  checkin_date: Date;
  checkout_date: Date;
  guests: string;
  addons: { name: string; price: number }[];
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  bookingPage: BookingPage;
  selectedDetails: ISelectedDetails;
  dockDetails: DockDetail;
  timer: string;
  kycModal: boolean;
  onBackBtnClick: () => void;
  onSuccessCall: () => void;
  handleOpenShareModal: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  noOfStar: number[];
  selectedStars: number;
  description: string;
  isLoading: boolean;
  paymentIntentId: string;
  detailsText: string;
  cancellationPolicy: string;
  isCancelled: boolean;
  isSuccessfullyCancelled: boolean;
  errorMsg: string;
  confirmationError: string;
  loginModal: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DockBookingPreviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  send: (message: Message) => void;
  createPaymentIntentApiCallId: string = "";
  cancelDockBookingApiCallId: string = "";
  ratingAndReviewApiCallId: string = "";
  createChatApiCallId: string = "";
  booking = new URL(document.baseURI).searchParams.get("booking");
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      noOfStar: [1,2,3,4,5],
      selectedStars: 0,
      description: '',
      paymentIntentId: "",
      detailsText: "",
      cancellationPolicy: "",
      isLoading: false,
      isCancelled: false,
      isSuccessfullyCancelled: false,
      errorMsg: "",
      confirmationError: "",
      loginModal: false,
      // Customizable Area End
    };
    // Customizable Area Start
    const blockId = uuidv4();
    this.send = (message) => runEngine.sendMessage(blockId, message);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setState({
      cancellationPolicy: `Boaters will only be entitled to a refund if the cancellation is at least ${
        this.props.dockDetails.cancellation_policy.split("_")[0]
      } hours before the reservation start time. (excluding service fee).`,
    });
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    // Customizable Area Start
    this.apiSuccessCallBackController(apiRequestCallId, responseJson);
    // Customizable Area End
  }

  cancelDockBooking = async () => {
    const token = await getStorageData("token");
    const dockId = getLastPartOfURL().url;
    this.setState({ isLoading: true, errorMsg: "" });
    this.cancelDockBookingApiCallId = sendAPIRequest(
      `bx_block_order_reservations/reservation_services/${this.props.dockDetails.id}/cancel`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token,
        },
        body: {
          data: {
            type: "cancel_dock_booking",
            attributes: {
              id: dockId,
            },
          },
        },
      }
    );
  };

  // Customizable Area Start
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if(prevProps.selectedDetails !== this.props.selectedDetails || this.props.bookingPage !== prevProps.bookingPage) {
      this.setState({
        detailsText: `We have successfully booked a dock from ${this.props.selectedDetails.checkin_date.toLocaleDateString(
          "en-GB",
          {
            day: "2-digit",
            month: "long",
          }
        )}
      –
      ${this.props.selectedDetails.checkout_date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      })}.`,
        cancellationPolicy: `Boaters will only be entitled to a refund if the cancellation is at least ${
          this.props.dockDetails.cancellation_policy.split("_")[0]
        } hours before the reservation start time. (excluding service fee).`,
      });
    }
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.cancelDockBookingApiCallId]: this.handleCancelBookingApiCall,
      [this.createPaymentIntentApiCallId]:
        this.handleCreatePaymentIntentApiCall,
      [this.createChatApiCallId]: this.handleCreateChatAPIResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  createPaymentIntentApiCall = async () => {
    const token = await getStorageData("token");
    this.setState({ isLoading: true, errorMsg: "" });
    this.createPaymentIntentApiCallId = sendAPIRequest(
      `bx_block_stripe_integration/payment_intents`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token,
        },
        body: {
          payment_intent: {
            reservation_id: this.props.dockDetails.id,
          },
        },
      }
    );
  };

  createChatApiCall = async () => {
    const token = await getStorageData("token");
    if(!token) {
      this.setState({ loginModal: true });
      return;
    }
    const boater_id = await getStorageData("loginId");
    this.setState({ isLoading: true, errorMsg: "" });
    this.createChatApiCallId = sendAPIRequest(
      `bx_block_chat/chats?type=boater`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token,
        },
        // Change host_id to API this.props.host.id when API is ready
        body: {
          chat: {
            host_id: this.props.dockDetails.host.id,
            boater_id,
            dock_listing_id: this.props.dockDetails.dockId
        }
        },
      }
    );
  };

  handleCancelBookingApiCall = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse("cancelDockBookingApiCallId", responseJSON))
      return;
    const response = responseJSON as {
      data?: { id: string; type: string; attributes: object };
      error?: string;
    };
    if (response.data) {
      this.setState({
        isCancelled: false,
        isSuccessfullyCancelled: true,
        detailsText: `Dock booking from ${this.props.selectedDetails.checkin_date.toLocaleDateString(
          "en-GB",
          {
            day: "2-digit",
            month: "long",
          }
        )}
      –
      ${this.props.selectedDetails.checkout_date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      })} has been cancelled.`,
        cancellationPolicy: `Your booking has been successfully cancelled on ${format(new Date(), "MM/dd/yyyy")}.`,
      });
    } else if (response.error) {
      this.setState({ confirmationError: response.error });
    }
  };
  postRatingReviewApiCall = async () => {
    const token = await getStorageData("token");
    this.ratingAndReviewApiCallId = sendAPIRequest(
      configJSON.reviewsPostPath,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token,
        },
        body: {
          review: {
            dock_listing_id: this.props.dockDetails.dockId,
            description: this.state.description,
            star: this.state.selectedStars,
          },
        },
      }
    );
  };
  handleCreatePaymentIntentApiCall = (
    responseJSON: Record<string, unknown>
  ) => {
    if (this.handleErrorResponse("createPaymentIntentApiCallId", responseJSON))
      return;
    const response = responseJSON as {
      data?: { id: string; type: string; attributes: object };
    };
    if (response.data) {
      this.setState({ paymentIntentId: response.data.id });
      this.props.onSuccessCall();
    }
  };

  handleCreateChatAPIResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse("createChatApiCallId", responseJSON)) return;
    const response = responseJSON as {
      data?: { id: string; type: string; attributes: object };
      meta?: string;
    };
    if (response.meta) {
      this.navigateToBoaterChat();
    }
  };

  handleErrorResponse = (
    type: string,
    responseJSON: Record<string, unknown>
  ) => {
    this.setState({ isLoading: false });

    const { errors } = responseJSON as { errors: string[] };
    if (errors) {
      if (type === "cancelDockBookingApiCallId") {
        this.setState({ confirmationError: errors[0] });
      } else {
        this.setState({ errorMsg: errors[0] });
      }
      return true;
    }
    return false;
  };

  handleCancelModal = async () => {
    const token = await getStorageData("token");
    if(!token) {
      this.setState({ loginModal: true });
      return;
    }
    this.setState({
      isCancelled: !this.state.isCancelled,
      confirmationError: "",
    });
  };
  getStarImageSrc = (index: number) => {
    return index < this.state.selectedStars ? assets.reviewLogo : assets.starLogo;
  };
  getAvatar = () => { 
    return this.props.dockDetails.host.avatar !== null ? (
      <Avatar src={this.props.dockDetails.host.avatar} />
    ) : (
      <Avatar src={assets.userLogo} style={{ opacity: 0.7 }} />
    );
  }
  handleStarClick = async (index: number) => {
    const token = await getStorageData("token");
    if(!token) {
      this.setState({ loginModal: true });
      return;
    }
    if (this.state.selectedStars === index + 1) {
      this.setState({selectedStars: 0})
    } else {
      this.setState({selectedStars: index + 1}) 
    }
  };
  handleDescription = async (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({description:event.target.value})
    const token = await getStorageData("token");
    if(!token) {
      this.setState({ loginModal: true, description: "" });
      return;
    }
  }

  handleShareReservationClick = async () => {
    const token = await getStorageData("token");
    if(!token) {
      this.setState({ loginModal: true });
      return;
    }
    this.props.handleOpenShareModal();
  };

  navigateToBoaterChat = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "BoaterChat");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  getPrice = (days: number) => {
    const weekend_price = this.props.dockDetails.weekend_price
      ? this.props.dockDetails.weekend_price
      : Number(this.props.dockDetails.prices.per_day)
    return (this.props.selectedDetails.checkin_date.getDay() === 0 ||
      this.props.selectedDetails.checkin_date.getDay() === 6
      ? weekend_price
      : Number(this.props.dockDetails.prices.per_day)) * days;
  };

  renderPaymentDetailCard = (onPayment?: () => void) => {
    const { selectedDetails, dockDetails } = this.props;
    const days = Math.ceil(
      (selectedDetails.checkout_date.getTime() -
        selectedDetails.checkin_date.getTime()) /
        (1000 * 3600 * 24)
    );

    return (
      <Box className="borderedBox">
        <Box className="flexGap">
          <Box className="flexItems justifySpaceBetween">
            <Typography variant="subtitle1" className="colorDarkBlue">
              {days} {configJSON.dayByPriceText}
            </Typography>
            <Typography variant="subtitle1" className="colorDarkBlue">
              ${dockDetails.dock_price}
            </Typography>
          </Box>
          <Box className="flexItems justifySpaceBetween">
            <Typography variant="subtitle1" className="colorDarkBlue">
              {configJSON.addonText}
            </Typography>
            <Typography variant="subtitle1" className="colorDarkBlue">
              ${dockDetails.total_add_ons_price}
            </Typography>
          </Box>
          <Box className="flexItems justifySpaceBetween">
            <Typography variant="subtitle1" className="colorDarkBlue">
              {configJSON.taxesText}
            </Typography>
            <Typography variant="subtitle1" className="colorDarkBlue">
              ${dockDetails.service_fee}
            </Typography>
          </Box>
        </Box>
        <Divider className="marginBottom10" />
        <Box className="flexItems justifySpaceBetween">
          <Box className="guestTotalFlex">
            <Typography variant="body1">
              {configJSON.daydockerGuestTotalLabel}
            </Typography>
            <Typography variant="subtitle1">
              {configJSON.afterTaxText}
            </Typography>
          </Box>
          <Typography variant="h6">${dockDetails.price}</Typography>
        </Box>
        {this.props.bookingPage !== BookingPage.Booked && (
          <>
            <Divider className="marginTop10" />
            <Button
              data-test-id="selectPaymentBtn"
              variant="contained"
              color="primary"
              className="marginTop0"
              onClick={() => {
                if (this.props.bookingPage === BookingPage.Details) {
                  this.postRatingReviewApiCall()
                  this.createPaymentIntentApiCall()
                } else {
                  onPayment && onPayment()
                }
              }}
            >
              {this.props.bookingPage === BookingPage.Details
                ? configJSON.selectPaymentMethodBtnText
                : configJSON.bookDockBtnText}
            </Button>
          </>
        )}
      </Box>
    );
  };

  renderButtons = () => {
    const { bookingPage } = this.props;
    return (
      <>
        {bookingPage === BookingPage.Booked &&
          !this.state.isSuccessfullyCancelled && (
            <Box className="flexItems">
              <Button
                data-test-id="messageHostBtn"
                variant="outlined"
                color="primary"
                onClick={this.createChatApiCall}
              >
                <ChatBubbleOutlineRoundedIcon
                  color="primary"
                  style={{ marginRight: "5px" }}
                />
                Message host
              </Button>
              <Button
                data-test-id="shareBtn"
                variant="outlined"
                color="primary"
                onClick={this.handleShareReservationClick}
              >
                <ShareRoundedIcon
                  color="primary"
                  style={{ marginRight: "5px" }}
                />
                Share reservation
              </Button>
            </Box>
          )
        }
      </>
    );
  };
  // Customizable Area End
}
